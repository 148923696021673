import React, { useState } from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ArticleProps } from './types';
import ArticleImageWrap from './ArticleImageWrap';
import ArticleContent from './ArticleContent';
import ArticleTitle from './ArticleTitle';
import Figure from '$components/CaseItem/Figure';
import Paragraph from '$components/Paragraph';

const StyledArticle = styled(AniLink)`
  color: var(--color-slate);
  display: grid;
  min-height: 100px;
  grid-template-areas:
    'title image'
    'content content';
  grid-template-columns: 2fr 1fr;
  grid-gap: 1.5em;
  align-items: center;
  min-width: 0;

  @media screen and (min-width: 640px) {
    grid-template-areas:
      'image .'
      'image title'
      'image content'
      'image .';
    grid-template-columns: minmax(8rem, 12.777777778vw) 356fr;
    grid-gap: 0 2.222222222vw;
  }

  @media screen and (min-width: 1680px) {
    grid-template-columns: 13.42em 356fr;
    grid-gap: 0 2.333333333em;
  }
`;

const Article: React.FC<ArticleProps> = ({ to, bg, cover, direction, image, title, shortDescription }) => {
  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  return (
    <StyledArticle
      to={to}
      bg={bg}
      cover={cover}
      direction={direction}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ArticleImageWrap>
        <Figure hover={hover} lessAnimation>
          <GatsbyImage image={image?.imageFile?.childImageSharp?.gatsbyImageData} alt={image?.alternativeText} />
        </Figure>
      </ArticleImageWrap>
      <ArticleTitle>{title}</ArticleTitle>
      <ArticleContent>
        <Paragraph article>{shortDescription}</Paragraph>
      </ArticleContent>
    </StyledArticle>
  );
};

export default Article;
