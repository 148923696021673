import React from 'react';
import styled from 'styled-components';

const StyledArticleImageWrap = styled.div`
  width: 5rem;
  height: 5rem;
  grid-area: image;
  justify-self: flex-end;

  @media screen and (min-width: 640px) {
    grid-row: image-start / image-end;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }

  img {
    width: 100%;
  }
`;

const ArticleImageWrap: React.FC = ({ children }) => <StyledArticleImageWrap>{children}</StyledArticleImageWrap>;

export default ArticleImageWrap;
