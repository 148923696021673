import React from 'react';
import styled from 'styled-components';

const StyledArticleTitle = styled.h5`
  font-size: 1.5em;
  line-height: 2rem;
  letter-spacing: -0.02em;
  margin: 0;
  color: var(--color-slate);
  grid-area: title;

  @media screen and (min-width: 640px) {
    font-size: 1.25em;
    margin-bottom: 0.5rem;
  }
`;

const ArticleTitle: React.FC = ({ children }) => <StyledArticleTitle>{children}</StyledArticleTitle>;

export default ArticleTitle;
