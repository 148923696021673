import React from 'react';
import styled from 'styled-components';

const StyledArticleContent = styled.div`
  grid-column: content-start / content-end;
  grid-area: content;
`;

const ArticleContent: React.FC = ({ children }) => <StyledArticleContent>{children}</StyledArticleContent>;

export default ArticleContent;
