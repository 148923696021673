import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { WrapMax } from '$components/Wraps';
import FadeInSection from '$components/FadeInSection';
import Article from './Article';
import { ArticlesProps } from './types';

const Outer = styled(WrapMax)`
  display: grid;
  grid-gap: 3.5em;
  padding-top: 3rem;
  margin-bottom: 6rem;

  @media screen and (min-width: 640px) {
    grid-template-columns: repeat(auto-fit, minmax(31.875em, 1fr));
    grid-gap: 6.111111111vw;
  }

  @media screen and (min-width: 1440px) {
    grid-gap: 5.5rem 5.5rem;
  }
`;

const BlockArticleListing: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      cms {
        articles(sort: "created_at:desc") {
          id
          Slug
          Title
          ShortDescription
          Image {
            url
            alternativeText
            imageFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 215)
              }
            }
          }
          Teaser {
            Heading
            Text
          }
        }
      }
    }
  `);

  const { articles }: ArticlesProps = data.cms;

  return (
    <FadeInSection>
      <Outer>
        {articles.map((article) => (
          <Article
            key={article.Slug}
            to={`/artiklar/${article.Slug}`}
            bg="var(--color-citrus5)"
            cover
            direction="left"
            image={article.Image}
            title={article.Title}
            shortDescription={article.ShortDescription}
            article={article}
          />
        ))}
      </Outer>
    </FadeInSection>
  );
};

export default BlockArticleListing;
